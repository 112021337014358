<template>
  <div class="h-100">
    <h4 class="profile-divider with-divider blue f-w-400">
      {{ $t("profile.account_preferences") }}
    </h4>
    <form action="" class="h-100 form-settings">
      <div class="platform">
        <p class="title">{{ $t("profile.platform_settings") }}</p>
        <div class="language-select">
          <BeMultiselect
            v-model="userLocale"
            :initialValue="currentLocaleValue"
            :options="locales"
            label="name"
            trackBy="value"
            :disabled="loading"
          ></BeMultiselect>
        </div>
      </div>
      <div class="notification">
        <p class="title">{{ $t("profile.sms_notifications") }}</p>
        <div>
          <div
            v-for="(userSetting, index) in settings"
            :key="`setting-${index}`"
          >
            <NotificationCheckbox
              v-if="userSetting.setting.id"
              :user-setting="userSetting"
            />
          </div>
          <div class="box"></div>
          <p class="text"></p>
        </div>
        <div class="notification-checkbox">
          <div class="box"></div>
          <p class="text"></p>
        </div>
        <span v-if="smsPriceSetting" class="text-muted f-w-600">
          {{ $t("profile.sms_notification_fees") }}
          <span class="text-danger f-w-600 p-0">
            {{ `${smsPriceSetting.value} ${currency}` }}
          </span>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BeMultiselect from "@/components/common/BeMultiselect";
import NotificationCheckbox from "@/components/profile/NotificationCheckbox";

export default {
  name: "FormSetting",
  components: { NotificationCheckbox, BeMultiselect },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("auth", ["user", "settings"]),
    ...mapGetters("auth", ["currency", "getAppLocale", "smsPriceSetting"]),

    locales() {
      return [
        { value: "fr", name: "Français" },
        { value: "en", name: "English" },
      ];
    },

    currentLocaleValue() {
      const l = this.locales.find(l => l.value === this.getAppLocale);
      return l !== undefined ? l : null;
    },

    userLocale: {
      get() {
        return this.currentLocaleValue;
      },
      set(locale) {
        this.loading = true;
        this.$store
          .dispatch("auth/changeLocale", locale.value)
          .finally(() => (this.loading = false));
      },
    },
  },
};
</script>

<style scoped></style>
