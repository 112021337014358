<template>
  <div class="notification-checkbox">
    <div class="pl-0 form-check">
      <div class="switch d-inline m-r-10">
        <input
          :id="`notificable-${userSetting.id}`"
          :checked="isChecked"
          type="checkbox"
          :disabled="loading"
          @change="updateUserSetting"
        />
        <label class="cr" :for="`notificable-${userSetting.id}`"></label>
      </div>
    </div>
    <label class="form-text text-muted">
      {{ userSetting.setting.name }}
    </label>
  </div>
</template>

<script>
import { returnBoolValue } from "@/helpers/helpers";

export default {
  name: "NotificationCheckbox",
  props: {
    userSetting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isChecked() {
      return returnBoolValue(this.userSetting.value);
    },
  },
  methods: {
    updateUserSetting() {
      this.loading = true;
      this.$store
        .dispatch("auth/updateNotificationSetting", {
          ...this.userSetting,
          value: !this.isChecked,
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped></style>
